<template>
  <v-dialog v-model="dialog" persistent max-width="900">
    <template v-slot:activator="{ on }">
      <v-btn class="mx-2" fab dark small color="primary" v-on="on">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline"
          >{{ editMode ? 'Редактирование' : 'Создание' }} {{ title }}а</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-text-field
            v-model="editedItem.name"
            label="Логин"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="editedItem.email"
            label="E-mail"
            outlined
            dense
          ></v-text-field>
          <v-select
            :items="role"
            v-model="editedItem.role"
            label="Роль"
            outlined
            dense
          ></v-select>
          <item-selector2
            :model="editedItem.partner"
            @itemSelected="editedItem.partner = $event"
            label="Данные клиента"
            tag="partner"
          />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="Close">Закрыть</v-btn>
        <v-btn color="blue darken-1" text @click="Save">{{
          editMode ? 'Обновить' : 'Создать'
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  DICTIONARY_TAG,
  GLOBAL_EVENT,
  USER_ROLE
} from '../../../utils/mainConsts';

import { inputSaveEdit } from '../../../mixins/inputSaveEdit';
import itemSelector2 from '../../ui/ItemSelector2';

export default {
  mixins: [inputSaveEdit],
  components: {
    'item-selector2': itemSelector2
  },
  data: () => ({
    updateEvent: GLOBAL_EVENT.updateDictionaryList,
    tag: DICTIONARY_TAG.user,
    rusTag: 'пользователь',
    title: 'Пользователь',
    editedItem: {},
    role: Object.keys(USER_ROLE).map(r => ({
      value: +r,
      text: USER_ROLE[r]
    }))
  }),
  methods: {
    isValid() {
      return true;
    },
    async itemConvertion() {
      if (this.editedItem.partner) {
        this.editedItem.partner = this.editedItem.partner._id.toString();
      }
      return true;
    },
    NullForm() {
      this.editedItem = {};
    }
  },
  validations: {},
  computed: {}
};
</script>

<style></style>
