<template>
  <v-dialog v-model="dialog" persistent max-width="1000">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on">
        <v-icon dark>mdi-application</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ title }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :single-select="singleSelect"
        item-key="_id"
        show-select
        class="elevation-1"
      >
        <template v-slot:item.image="{ item }">
          <v-img
            :src="`${serverURI}/images/preview/${imageUrl(item)}`"
            :lazy-src="`${serverURI}/images/preview/${imageUrl(item)}`"
            max-width="128"
            class="grey lighten-2"
          />
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeWindow">Закрыть</v-btn>
        <v-btn color="blue darken-1" text @click="selectItems">Выбрать</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// UniList - универсальный список. Позволяющий выбрать один или множество элементов из модального окна.
// Применяется в том случае если нужно создать или редактировать элемент один из свойств которого
// являются другие элементы (Например создаем Договор и в свойствах договора нужно выбрать Контрагента)
// Принимает в себя:
// title: Для заголовка
// singleSelect: выбрать один или множество элементов
// headers: Структура таблицы (Можно взять из стэйтов)
// items: Список элементов заранее загруженный из базы данных
// toSelect: Элемент/элементы уже выбарнные в таблице (в случае если происходит редактирование)

// В компоненте установлен watcher. Следит за тем, чтобы переданные к выделению через toSelect элементы
// отметились в таблице. В две проходки: 1. вытаскивает id переданных, 2. находит по id элементы в items и
// заталкивает их в актуальные selected.

import { mapState } from "vuex";

export default {
  name: "universal-selector",
  props: {
    title: {
      type: String,
      default: "Универсальный список",
    },
    singleSelect: {
      type: Boolean,
      default: true,
    },
    headers: {
      type: Array,
      default: () => [{ text: "Наименование", value: "name" }],
    },
    items: Array,
    toSelect: {
      type: Array,
      default: () => [],
    },
    loadedSelection: {
      type: Array,
    },
  },
  data: () => ({
    selected: [],
    search: "",
    dialog: false,
  }),
  watch: {
    // В случае если идет редактирование и нужно предварительно отметить уже выбранные элементы
    toSelect(value) {
      if (value) {
        if (!Array.isArray(value)) {
          value = [value];
        }
        const ids = value.map((e) => {
          return e._id;
        });
        if (value.length > 0) {
          this.selected = [];
          this.items.forEach((element) => {
            if (ids.indexOf(element._id) !== -1) {
              this.selected.push(element);
            }
          });
        }
      }
    },

    loadedSelection(value) {
      if (value) {
        if (!Array.isArray(value)) {
          value = [value];
        }
        const ids = value.map((e) => {
          return e._id;
        });
        if (value.length > 0) {
          this.selected = [];
          this.items.forEach((element) => {
            if (ids.indexOf(element._id) !== -1) {
              this.selected.push(element);
            }
          });
        }
      }
    },
  },
  mounted() {},
  computed: {
    ...mapState(["serverURI"]),
  },
  methods: {
    closeWindow() {
      this.dialog = false;
    },
    selectItems() {
      this.dialog = false;
      // Возвращаем именно копию объекта. Иначе произойдет зацикливание
      this.$emit("itemsSelected", [...this.selected]);
    },
    // Проверяет если изображений много, то в список попадет только первое.
    // Если одно, то оно и попадет
    imageUrl: (item) => {
      return Array.isArray(item.image) ? item.image[0].fileName : item.fileName;
    },
  },
};
</script>

<style></style>
