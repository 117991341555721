<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title
          >Уведомление</v-card-title
        >

        <v-card-text>{{ message }}</v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="confirm">Выполнить</v-btn>
          <v-btn color="primary" text @click="cancel">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { CONFIRM_ACTION, GLOBAL_EVENT } from '../utils/mainConsts';

export default {
  data: () => ({
    dialog: false,
    message: '',
    action: '',
    item: {},
    itemType: ''
  }),
  mounted() {
    this.$bus.$on(
      GLOBAL_EVENT.openConfirmWindow,
      ({ item, itemType, message, action }) => {
        this.dialog = true;
        this.message = message;
        this.action = action;
        this.item = item;
        this.itemType = itemType;
      }
    );
  },
  methods: {
    cancel() {
      this.$emit('cancelAnswer');
      this.dialog = false;
    },
    confirm() {
      if (this.action === CONFIRM_ACTION.delete) {
        this.$bus.$emit(GLOBAL_EVENT.confirmDelete, {
          item: this.item,
          itemType: this.itemType
        });
      }
      this.dialog = false;
    }
  }
};
</script>

<style></style>
