<template>
  <div v-if="view === 'string'">
    <v-text-field v-model="itemString" :label="title"></v-text-field>
  </div>
  <div v-else-if="view === 'image'">
    <v-row>
      <v-col>
        <v-card>
          <v-container fluid>
            <v-row>
              <v-col
                v-for="n in selected"
                :key="n.fileName"
                class="d-flex child-flex"
                cols="4"
              >
                <v-card flat tile class="d-flex">
                  <v-img
                    :src="path + n.fileName"
                    aspect-ratio="1"
                    class="grey lighten-2"
                    max-height="100"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    view: {
      type: String,
      default: 'string' // string, image
    },
    selected: Array
  },
  data: () => ({
    itemString: ''
  }),
  computed: {
    path: () => {
      return 'http://localhost:3000/images/preview/';
    }
  },
  watch: {
    selected(value) {
      if (!value) {
        return;
      }
      // Строковое представление выбранного
      this.itemString = value.reduce((prev, curr) => {
        return prev + (curr.name || curr.number) + ';';
      }, '');
    }
  }
};
</script>

<style></style>
