<template>
  <v-dialog v-model="dialog" persistent max-width="900">
    <template v-slot:activator="{ on }">
      <v-btn class="mx-2" fab dark small color="primary" v-on="on">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline"
          >{{ editMode ? 'Редактирование' : 'Создание' }} объекта</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-text-field
            v-model="editedItem.name"
            label="Наименование"
          ></v-text-field>
          <v-select
            :items="role"
            v-model="editedItem.role"
            label="Тип"
          ></v-select>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="Close">Закрыть</v-btn>
        <v-btn color="blue darken-1" text @click="Save">
          {{ editMode ? 'Обновить' : 'Создать' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';
import { DICTIONARY_TAG } from '../../../utils/mainConsts';

export default {
  data: () => ({
    tag: DICTIONARY_TAG.nomenclature,
    rusTag: 'номенклатура',
    dialog: false,
    editedItem: {
      role: 'Запчасть'
    },
    editMode: false,
    role: ['Запчасть', 'Иной товар']
  }),
  validations: {},

  computed: {},

  async mounted() {
    // Событие открытия окна извне
    this.$bus.$on(`${this.tag}OpenItem`, async ({ editMode, item }) => {
      this.dialog = true;
      this.editMode = editMode;

      // Если в режиме редактирования, то пытаемся также загрузить редактируемый объект
      if (editMode) {
        const loadedItem = await this.$store.dispatch('uniFetch', {
          name: this.tag,
          id: item.id
        });
        this.editedItem = {
          ...loadedItem
        };
      }
    });
  },

  methods: {
    ...mapMutations(['setMessageData']),

    async Save() {
      if (this.editMode === false) {
        // Режим создания нового элемента
        try {
          await this.$store.dispatch('uniAdd', {
            name: this.tag,
            item: this.editedItem
          });
          this.$bus.$emit('updateDictionaryList', this.tag);
          this.setMessageData({
            message: `Новый ${this.rusTag} успешно добавлен`,
            type: 'snackbar'
          });
          this.editMode = false;
          this.dialog = false;
          this.editedItem = {}; // обнулить все поля формы
        } catch (error) {
          console.log(error);
          this.setMessageData({
            message: `При добавлении нового ${this.rusTag}ы произошла ошибка: введенные данные неверны!`,
            type: 'snackbar'
          });
        }
      } else {
        // Режим редактирования элемента
        try {
          await this.$store.dispatch('uniEdit', {
            name: this.tag,
            id: this.editedItem._id,
            item: this.editedItem
          });
          this.$bus.$emit('updateDictionaryList', this.tag);
          this.setMessageData({
            message: `Данные ${this.editedItem.name} успешно обновлены`,
            type: 'snackbar'
          });
          this.editMode = false;
          this.dialog = false;
          this.editedItem = {}; // обнулить все поля формы
        } catch (error) {
          console.log(error);
          this.setMessageData({
            message: `При редактировании ${this.rusTag}а произошла ошибка: введенные данные неверны!`,
            type: 'snackbar'
          });
        }
      }
    },

    Close() {
      this.editMode = false;
      this.dialog = false;
    }
  }
};
</script>

<style></style>
