import { mapMutations } from 'vuex';

export const inputSaveEdit = {
  data: () => ({
    updateEvent: 'Mixin default',
    tag: 'Mixin default',
    rusTag: 'Mixin default',
    title: 'Mixin default',
    dialog: false,
    editedItem: {},
    editMode: false,
    loading: false
  }),

  async mounted() {
    // Событие открытия окна извне
    this.$bus.$on(`${this.tag}OpenItem`, async ({ editMode, item }) => {
      this.dialog = true;
      this.editMode = editMode;

      // Если в режиме редактирования, то пытаемся также загрузить редактируемый объект
      if (editMode) {
        const loadedItem = await this.$store.dispatch('uniFetch', {
          name: this.tag,
          id: item.id
        });
        this.editedItem = {
          ...loadedItem
        };

        this.afterMount();
      }
    });
  },

  methods: {
    ...mapMutations(['setMessageData']),

    async afterMount() {},

    async itemConvertion() {},

    isValid() {},

    async Save() {
      // Некоторое преобразование перед сохранением
      const valid = await this.itemConvertion();
      if (!valid) {
        // Конвертация прошла с ошибкой, не продолжаем
        return;
      }
      if (this.isValid()) {
        if (this.editMode === false) {
          // Режим создания нового элемента
          try {
            this.loading = true;
            await this.$store.dispatch('uniAdd', {
              name: this.tag,
              item: this.editedItem
            });
            this.loading = false;
            this.$bus.$emit(this.updateEvent, this.tag);
            this.setMessageData({
              message: `Новый ${this.rusTag} успешно добавлен`,
              type: 'snackbar'
            });
            this.editMode = false;
            this.dialog = false;
            this.NullForm(); // обнулить все поля формы
          } catch (error) {
            console.log(error);
            this.setMessageData({
              message: `При добавлении нового ${this.rusTag}а произошла ошибка: введенные данные неверны!`,
              type: 'snackbar'
            });
          }
        } else {
          // Режим редактирования элемента
          try {
            // _id в объекте сохранения не нужне. удаляем
            const item = this.editedItem;
            const id = this.editedItem._id.toString();
            delete item._id;

            this.loading = true;
            await this.$store.dispatch('uniEdit', {
              name: this.tag,
              id: id,
              item: item
            });
            this.loading = false;
            this.$bus.$emit(this.updateEvent, this.tag);
            this.setMessageData({
              message: `Данные ${this.editedItem.name} успешно обновлены`,
              type: 'snackbar'
            });
            this.editMode = false;
            this.dialog = false;
            this.NullForm(); // обнулить все поля формы
          } catch (error) {
            console.log(error);
            this.setMessageData({
              message: `При редактировании ${this.rusTag}а произошла ошибка: введенные данные неверны!`,
              type: 'snackbar'
            });
          }
        }
      } else {
        this.setMessageData({
          message: `Просмотрите форму! Не заполненные или заполненные неверно поля отмечены красным!`,
          type: 'snackbar'
        });
      }
    },

    NullForm() {},

    Close() {
      this.NullForm();
      this.editMode = false;
      this.dialog = false;
    }
  }
};
