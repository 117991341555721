<template>
  <div>
    <component
      :is="mode"
      :data="payload"
      @goToDictionary="goToDictionary($event)"
    ></component>
  </div>
</template>

<script>
import ListComponent from '../components/dictionaries/List';
import MenuComponent from '../components/dictionaries/Menu';
import { mapGetters } from 'vuex';

export default {
  name: 'dictionary',
  components: {
    'list-component': ListComponent,
    'menu-component': MenuComponent
  },
  data: () => ({
    mode: 'menu-component',
    payload: {}
  }),
  computed: {
    ...mapGetters(['dictionariesList'])
  },
  mounted() {
    this.$bus.$on('goBack', () => {
      this.showMenu();
    });
    this.showMenu();
  },
  methods: {
    // Вывод конкретного раздела по его тегу
    goToDictionary(tag) {
      this.payload = tag;
      this.mode = 'list-component';
    },
    // Вывод списка всех разделов
    showMenu() {
      this.payload = this.dictionariesList;
      this.mode = 'menu-component';
    }
  }
};
</script>

<style></style>
