import { USER_ROLE } from './mainConsts';

export const listPostConvertor = {
  user: values => {
    return values.map(v => ({
      ...v,
      role: USER_ROLE[v.role]
    }));
  }
};
