<template>
  <v-dialog v-model="dialog" persistent max-width="900">
    <template v-slot:activator="{ on }">
      <v-btn class="mx-2" fab dark small color="primary" v-on="on">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline"
          >{{ editMode ? 'Редактирование' : 'Создание' }} договора</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="editedItem.name"
                label="Наименование"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="editedItem.number"
                label="№"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="editedItem.date"
                label="Дата заключения"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="editedItem.expiresAt"
                label="Дата прекращения"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="partnerString"
                label="Контрагент"
              ></v-text-field>
            </v-col>
            <v-col md="auto">
              <univarsal-list
                title="Объекты"
                :items="partnerItems"
                :headers="partnerHeaders"
                :singleSelect="true"
                :toSelect="[...selectedPartner]"
                @itemsSelected="partnerSelected($event)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="poiString"
                label="Объект(ы)"
              ></v-text-field>
            </v-col>
            <v-col md="auto">
              <univarsal-list
                title="Объекты"
                :items="poiItems"
                :headers="poiHeader"
                :singleSelect="false"
                :toSelect="selectedPoi"
                @itemsSelected="poiItemsSelected($event)"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="Close">Закрыть</v-btn>
        <v-btn color="blue darken-1" text @click="Save">{{
          editMode ? 'Обновить' : 'Создать'
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';
import univarsalList from '@/components/UniList';

export default {
  components: {
    'univarsal-list': univarsalList
  },
  data: () => ({
    tag: 'contract',
    rusTag: 'договор',
    dialog: false,
    editedItem: {},
    editMode: false,
    poiItems: [],
    poiHeader: [],
    selectedPoi: [],
    poiString: '',
    partnerItems: [],
    partnerHeaders: [],
    selectedPartner: {},
    partnerString: ''
  }),
  validations: {},

  computed: {},

  watch: {
    selectedPoi(value) {
      this.poiString = '';
      value.forEach(element => {
        this.poiString += element.name + '; ';
      });
      this.editedItem.poi = value.map(p => {
        return p._id;
      });
    },
    selectedPartner(value) {
      this.partnerString = value.name;
      this.editedItem.partner = value._id;
    }
  },

  async mounted() {
    // Событие открытия окна извне
    this.$bus.$on(`${this.tag}OpenItem`, async ({ editMode, item }) => {
      this.dialog = true;
      this.editMode = editMode;

      // Если в режиме редактирования, то пытаемся также загрузить редактируемый объект
      if (editMode) {
        // Загрузка выбранного элемента
        const loadedItem = await this.$store.dispatch(
          `${this.tag}Fetch`,
          item.id
        );
        this.editedItem = {
          ...loadedItem
        };

        // Преобразования перечислений (для работы универсального списка)
        this.selectedPoi = this.editedItem.poi;
        this.selectedPartner = this.editedItem.partner;

        // console.log('Загружены')
      }
    });

    // Предзагрузка
    this.poiItems = await this.$store.dispatch('pointFetchAll');
    this.poiHeader = this.$store.getters.dictionaryHeaders('point');
    this.partnerItems = await this.$store.dispatch('partnerFetchAll');
    this.partnerHeaders = this.$store.getters.dictionaryHeaders('partner');
  },

  methods: {
    ...mapMutations(['setMessageData']),

    async Save() {
      if (this.editMode === false) {
        // Режим создания нового элемента
        try {
          await this.$store.dispatch(`${this.tag}Add`, this.editedItem);
          this.$bus.$emit('updateDictionaryList', this.tag);
          this.setMessageData({
            message: `Новый ${this.rusTag} успешно добавлен`,
            type: 'snackbar'
          });
          this.editMode = false;
          this.dialog = false;
          this.editedItem = {}; // обнулить все поля формы
        } catch (error) {
          console.log(error);
          this.setMessageData({
            message: `При добавлении нового ${this.rusTag}а произошла ошибка: введенные данные неверны!`,
            type: 'snackbar'
          });
        }
      } else {
        // Режим редактирования элемента
        try {
          await this.$store.dispatch(`${this.tag}Edit`, {
            id: this.editedItem._id,
            item: this.editedItem
          });
          this.$bus.$emit('updateDictionaryList', this.tag);
          this.setMessageData({
            message: `Данные ${this.editedItem.name} успешно обновлены`,
            type: 'snackbar'
          });
          this.editMode = false;
          this.dialog = false;
          this.editedItem = {}; // обнулить все поля формы
        } catch (error) {
          console.log(error);
          this.setMessageData({
            message: `При редактировании ${this.rusTag}а произошла ошибка: введенные данные неверны!`,
            type: 'snackbar'
          });
        }
      }
    },

    poiItemsSelected(items) {
      this.selectedPoi = items;
    },

    partnerSelected(items) {
      this.selectedPartner = items[0];
    },

    Close() {
      this.editMode = false;
      this.dialog = false;
    }
  }
};
</script>

<style></style>
