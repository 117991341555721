<template>
  <v-list two-line subheader>
    <v-subheader inset>Справочники</v-subheader>
    <div v-for="item in data" :key="item.tag">
      <v-list-item
        v-if="+userRole >= item.level"
        @click.prevent="goTo(item.tag)"
        link
      >
        <v-list-item-avatar>
          <v-icon :class="[item.iconClass]" v-text="item.icon"></v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="item.title"></v-list-item-title>
          <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-list>
</template>

<script>
export default {
  name: 'DictionaryMenu',
  props: ['data'],
  computed: {
    userRole() {
      return this.$store.state.role;
    }
  },
  methods: {
    goTo(tag) {
      this.$emit('goToDictionary', tag);
    }
  }
};
</script>

<style></style>
