<template>
  <v-dialog v-model="dialog" persistent max-width="900">
    <template v-slot:activator="{ on }">
      <v-btn class="mx-2" fab dark small color="primary" v-on="on">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline"
          >{{ editMode ? 'Редактирование' : 'Создание' }} {{ title }}а</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-text-field
            v-model="editedItem.number"
            label="Номер"
          ></v-text-field>
          <v-text-field v-model="editedItem.mark" label="Марка"></v-text-field>
          <v-text-field
            v-model="editedItem.model"
            label="Модель"
          ></v-text-field>
          <v-text-field
            v-model="editedItem.bodyNumber"
            label="Кузов"
          ></v-text-field>
          <v-text-field
            v-model="editedItem.engine"
            label="Двигатель"
          ></v-text-field>
          <v-text-field v-model="editedItem.year" label="Год"></v-text-field>
          <v-select
            :items="wheel"
            v-model="editedItem.wheel"
            label="Руль"
          ></v-select>
          <v-select
            :items="kpp"
            v-model="editedItem.kpp"
            label="КПП"
          ></v-select>
          <v-text-field v-model="editedItem.price" label="Цена"></v-text-field>
          <item-selector
            tag="image"
            title="Изображение"
            view="image"
            :singleSelect="false"
            :toSelect="editedItem.image"
            @selected="editedItem.image = $event"
          />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="Close">Закрыть</v-btn>
        <v-btn color="blue darken-1" text @click="Save">
          {{ editMode ? 'Обновить' : 'Создать' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DICTIONARY_TAG, GLOBAL_EVENT } from '../../../utils/mainConsts';

import { inputSaveEdit } from '../../../mixins/inputSaveEdit';
import itemSelector from '../../ui/ItemSelector';

export default {
  mixins: [inputSaveEdit],
  components: {
    'item-selector': itemSelector
  },
  data: () => ({
    updateEvent: GLOBAL_EVENT.updateDictionaryList,
    tag: DICTIONARY_TAG.donor,
    rusTag: 'донор',
    title: 'Донор',
    editedItem: {
      wheel: 'Правый',
      kpp: 'АКПП'
    },
    wheel: ['Правый', 'Левый', 'Нет'],
    kpp: ['АКПП', 'МКПП', 'Робот', 'Вариатор']
  }),
  validations: {},

  computed: {}
};
</script>

<style></style>
