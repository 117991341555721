<template>
  <div style="padding-top: 10px">
    <v-card>
      <v-card-title>
        <v-btn color="primary" fab x-small @click.prevent="back()">
          <v-icon>mdi-arrow-left</v-icon> </v-btn
        >Справочник
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <component :is="data + '-item'" />
      <confirm-window />
      <v-data-table
        dense
        v-model="selected"
        :headers="headers"
        :items="items"
        :search="search"
        show-select
        item-key="_id"
        class="elevation-1"
      >
        <template #item.actions="{ item }">
          <v-btn color="primary" fab x-small @click.prevent="editItem(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            fab
            x-small
            @click.prevent="deleteRequest(item)"
          >
            <v-icon>mdi-pencil-remove</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.image="{ item }">
          <div v-if="item.image[0]">
            <v-img
              :src="`${serverURI}/images/preview/${item.image[0].fileName}`"
              :lazy-src="`${serverURI}/images/preview/${item.image[0].fileName}`"
              max-width="128"
              class="grey lighten-2"
            />
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import PartnerInput from "./inputs/Partner";
import ContractInput from "./inputs/Contract";
import POIInput from "./inputs/Point";
import NomenclatureInput from "./inputs/Nomenclature";
import DonorInput from "./inputs/Donor";
import UserInput from "./inputs/User";

import ConfirmWindow from "../ConfirmWindow";

import { mapMutations, mapState } from "vuex";
import {
  DICTIONARY_TAG,
  CONFIRM_ACTION,
  GLOBAL_EVENT,
  ITEM_TYPE,
} from "../../utils/mainConsts";
import { listPostConvertor } from "../../utils/convertors";

export default {
  name: "dictionary-list",
  components: {
    "partner-item": PartnerInput,
    "contract-item": ContractInput,
    "point-item": POIInput,
    "nomenclature-item": NomenclatureInput,
    "donor-item": DonorInput,
    "confirm-window": ConfirmWindow,
    "user-item": UserInput,
  },
  props: {
    // Название справочника. От него будет зависить какие методы будут вызываться
    data: {
      type: String,
      default: DICTIONARY_TAG.partner,
    },
  },
  data: () => ({
    listType: ITEM_TYPE.dictionary,
    search: "",
    selected: [],
    payloadItems: [],
    payloadHeaders: [],
    items: [],
    headers: [],
  }),
  watch: {
    payloadItems(value) {
      this.items = value;
    },
    payloadHeaders(value) {
      this.headers = value;
    },
  },
  computed: {
    ...mapState(["serverURI"]),
  },
  created() {
    this.update(this.data);
  },
  mounted() {
    // получено событие на обновление списка (перезагрузка из базы данных)
    this.$bus.$on(GLOBAL_EVENT.updateDictionaryList, (tag) => {
      this.update(tag);
    });

    // получено событие от подтверждающего окна на удаление элемента
    this.$bus.$on(GLOBAL_EVENT.confirmDelete, async ({ item, itemType }) => {
      if (itemType !== this.listType) {
        // Событие пришло не для этого листа
        return;
      }
      try {
        await this.$store.dispatch(`${this.data}Delete`, item);
        this.update(this.data);
        this.setMessageData({
          message: `Данные ${item.name} успешно удалено`,
          type: "snackbar",
        });
      } catch (e) {
        console.log(e);
        this.setMessageData({
          message: "При удалении произошла ошибка",
          type: "snackbar",
        });
      }
    });
  },
  methods: {
    ...mapMutations(["setMessageData"]),
    back() {
      this.$bus.$emit("goBack");
    },
    async update(tag) {
      let res;
      if (
        this.data === DICTIONARY_TAG.partner ||
        this.data === DICTIONARY_TAG.contract ||
        this.data === DICTIONARY_TAG.point
      ) {
        // Устаревший метод.
        res = await this.$store.dispatch(`${this.data}FetchAll`);
      } else {
        res = await this.$store.dispatch("uniFetchAll", { name: this.data });
      }

      this.payloadItems = res.map((el) => {
        return {
          ...el,
          id: el._id,
          actions: {},
        };
      });

      // Конвертация значений в читаемые свойства
      if (listPostConvertor[this.data]) {
        this.payloadItems = listPostConvertor[this.data](this.payloadItems);
      }

      this.payloadHeaders = [
        ...this.$store.getters.dictionaryHeaders(this.data),
        { text: "Действия", value: "actions" },
      ];
    },
    editItem(item) {
      this.$bus.$emit(`${this.data}OpenItem`, {
        editMode: true,
        item,
      });
    },
    deleteRequest(item) {
      this.$bus.$emit(GLOBAL_EVENT.openConfirmWindow, {
        item,
        itemType: ITEM_TYPE.dictionary,
        message: `Действительно хотите удалить ${item.name}?`,
        action: CONFIRM_ACTION.delete,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep tbody tr {
  cursor: pointer;
}
</style>
