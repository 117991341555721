<template>
  <v-dialog v-model="dialog" persistent max-width="900">
    <template v-slot:activator="{ on }">
      <v-btn class="mx-2" fab dark small color="primary" v-on="on">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline"
          >{{ editMode ? 'Редактирование' : 'Создание' }} контрагента</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-radio-group
            v-model="editedItem.orgType"
            :error-messages="orgTypeErrors"
          >
            <v-row>
              <v-col>
                <v-radio
                  label="Огранизация/ИП"
                  :value="0"
                  @change="$v.editedItem.orgType.$touch()"
                ></v-radio>
              </v-col>
              <v-col>
                <v-radio
                  label="Физическое лицо"
                  :value="1"
                  @change="$v.editedItem.orgType.$touch()"
                ></v-radio>
              </v-col>
            </v-row>
          </v-radio-group>
          <div v-if="editedItem.orgType === 0">
            <v-text-field
              v-model.trim="editedItem.name"
              :counter="99"
              @input="$v.editedItem.name.$touch()"
              @blur="$v.editedItem.name.$touch()"
              :error-messages="nameErrors"
              label="Наименование"
            ></v-text-field>
            <v-text-field
              v-model.trim="editedItem.shortName"
              :counter="30"
              @input="$v.editedItem.shortName.$touch()"
              @blur="$v.editedItem.shortName.$touch()"
              :error-messages="shortNameErrors"
              label="Сокращенное наименование"
            ></v-text-field>
            <v-text-field
              v-model="editedItem.director"
              :counter="99"
              @input="$v.editedItem.director.$touch()"
              @blur="$v.editedItem.director.$touch()"
              :error-messages="directorErrors"
              label="ФИО представителя"
            ></v-text-field>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="editedItem.inn"
                  :counter="12"
                  @input="$v.editedItem.inn.$touch()"
                  @blur="$v.editedItem.inn.$touch()"
                  :error-messages="innErrors"
                  label="ИНН"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="editedItem.kpp"
                  :counter="9"
                  @input="$v.editedItem.kpp.$touch()"
                  @blur="$v.editedItem.kpp.$touch()"
                  :error-messages="kppErrors"
                  label="КПП"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-text-field
              v-model="editedItem.ogrn"
              :counter="15"
              @input="$v.editedItem.ogrn.$touch()"
              @blur="$v.editedItem.ogrn.$touch()"
              :error-messages="ogrnErrors"
              label="ОГРН"
            ></v-text-field>
            <v-text-field
              v-model="editedItem.creationDate"
              :counter="10"
              @input="$v.editedItem.creationDate.$touch()"
              @blur="$v.editedItem.creationDate.$touch()"
              :error-messages="creationDateErrors"
              label="Дата присвоения ОГРН"
            ></v-text-field>
            <v-text-field
              v-model.trim="editedItem.bank"
              :counter="199"
              @input="$v.editedItem.bank.$touch()"
              @blur="$v.editedItem.bank.$touch()"
              :error-messages="bankErrors"
              label="Банковские реквизиты (р/с, банк, к/с, БИК банка)"
            ></v-text-field>
          </div>
          <div v-if="editedItem.orgType === 1">
            <v-text-field
              v-model="editedItem.name"
              :counter="99"
              @input="$v.editedItem.name.$touch()"
              @blur="$v.editedItem.name.$touch()"
              :error-messages="nameErrors"
              label="ФИО"
            ></v-text-field>
            <v-text-field
              v-model.trim="editedItem.snils"
              :counter="11"
              @input="$v.editedItem.snils.$touch()"
              @blur="$v.editedItem.snils.$touch()"
              :error-messages="snilsErrors"
              hint="Без проблеов и дефисов"
              label="СНИЛС"
            ></v-text-field>
            <v-text-field
              v-model="editedItem.shortName"
              :counter="30"
              @input="$v.editedItem.shortName.$touch()"
              @blur="$v.editedItem.shortName.$touch()"
              :error-messages="shortNameErrors"
              label="И.О. Фамилия (для реквизитов)"
            ></v-text-field>
            <v-text-field
              v-model="editedItem.passportNum"
              :counter="10"
              @input="$v.editedItem.passportNum.$touch()"
              @blur="$v.editedItem.passportNum.$touch()"
              :error-messages="passportNumErrors"
              label="Паспорт серия и №"
            ></v-text-field>
            <v-text-field
              v-model="editedItem.passportOrg"
              :counter="199"
              @input="$v.editedItem.passportOrg.$touch()"
              @blur="$v.editedItem.passportOrg.$touch()"
              :error-messages="passportOrgErrors"
              label="Кем и когда выдан"
            ></v-text-field>
            <v-text-field
              v-model="editedItem.inn"
              :counter="12"
              @input="$v.editedItem.inn.$touch()"
              @blur="$v.editedItem.inn.$touch()"
              :error-messages="innErrors"
              label="ИНН"
            ></v-text-field>
          </div>
          <v-text-field
            v-model="editedItem.address"
            :counter="199"
            @input="$v.editedItem.address.$touch()"
            @blur="$v.editedItem.address.$touch()"
            :error-messages="addressErrors"
            label="Адрес"
          ></v-text-field>
          <v-text-field
            v-model="editedItem.telephone"
            :counter="11"
            @input="$v.editedItem.telephone.$touch()"
            @blur="$v.editedItem.telephone.$touch()"
            :error-messages="telephoneErrors"
            label="Телефон"
          ></v-text-field>
          <v-text-field
            v-model="editedItem.email"
            :counter="99"
            @input="$v.editedItem.email.$touch()"
            @blur="$v.editedItem.email.$touch()"
            :error-messages="emailErrors"
            label="e-Mail"
          ></v-text-field>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="Close">Закрыть</v-btn>
        <v-btn color="blue darken-1" text @click="Save">
          {{ editMode ? 'Обновить' : 'Создать' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  DICTIONARY_TAG,
  GLOBAL_EVENT,
  VALID_ERRORS
} from '../../../utils/mainConsts';

import { inputSaveEdit } from '../../../mixins/inputSaveEdit';

import { checkValid } from '../../../utils/helpers';

import {
  between,
  required,
  minLength,
  maxLength,
  numeric,
  helpers,
  email
} from 'vuelidate/lib/validators';

// Проверки даты на формат ДД.ММ.ГГГГ через регулярное выражение
const dateMatch = /^((((0[13578])|([13578])|(1[02]))[.](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[.](([1-9])|([0-2][0-9])|(30)))|((2|02)[.](([1-9])|([0-2][0-9]))))[.]\d{4}$|^\d{4}$/;

const confirmData = value => {
  return dateMatch.test(value);
};

export default {
  mixins: [inputSaveEdit],
  data: () => ({
    updateEvent: GLOBAL_EVENT.updateDictionaryList,
    tag: DICTIONARY_TAG.partner,
    rusTag: 'контрагент',
    title: 'Контрагент',
    editedItem: {}
  }),
  validations: {
    editedItem: {
      orgType: {
        between: between(0, 1)
      },
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(99)
      },
      shortName: {
        maxLength: maxLength(30)
      },
      director: {
        maxLength: maxLength(99)
      },
      inn: {
        required,
        maxLength: maxLength(12),
        numeric: numeric
      },
      kpp: {
        maxLength: maxLength(9),
        numeric: numeric
      },
      ogrn: {
        required,
        maxLength: maxLength(15),
        numeric: numeric
      },
      creationDate: {
        maxLength: maxLength(10),
        confirmData
      },
      bank: {
        maxLength: maxLength(199)
      },
      snils: {
        required,
        maxLength: maxLength(11),
        numeric: numeric
      },
      passportNum: {
        required,
        maxLength: maxLength(10),
        numeric: numeric
      },
      passportOrg: {
        required,
        maxLength: maxLength(199)
      },
      address: {
        required,
        maxLength: maxLength(199)
      },
      telephone: {
        required,
        maxLength: maxLength(11),
        numeric: numeric
      },
      email: {
        maxLength: maxLength(99),
        email
      }
    }
  },
  computed: {
    orgTypeErrors() {
      const errors = [];
      if (!this.$v.editedItem.orgType.$dirty) return errors;
      !this.$v.editedItem.orgType.between && errors.push(VALID_ERRORS.orgType);
      return errors;
    },

    nameErrors() {
      const errors = [];
      if (!this.$v.editedItem.name.$dirty) return errors;
      !this.$v.editedItem.name.required && errors.push(VALID_ERRORS.required);
      !this.$v.editedItem.name.minLength &&
        errors.push(VALID_ERRORS.minLength(3));
      !this.$v.editedItem.name.maxLength &&
        errors.push(VALID_ERRORS.maxLength(99));
      return errors;
    },

    shortNameErrors() {
      const errors = [];
      if (!this.$v.editedItem.shortName.$dirty) return errors;
      !this.$v.editedItem.shortName.maxLength &&
        errors.push(VALID_ERRORS.maxLength(30));
      return errors;
    },

    directorErrors() {
      const errors = [];
      if (!this.$v.editedItem.director.$dirty) return errors;
      !this.$v.editedItem.director.maxLength &&
        errors.push(VALID_ERRORS.maxLength(99));
      return errors;
    },

    innErrors() {
      const errors = [];
      if (!this.$v.editedItem.inn.$dirty) return errors;
      !this.$v.editedItem.inn.required && errors.push(VALID_ERRORS.required);
      !this.$v.editedItem.inn.maxLength &&
        errors.push(VALID_ERRORS.maxLength(12));
      !this.$v.editedItem.inn.numeric && errors.push(VALID_ERRORS.numeric);
      return errors;
    },

    kppErrors() {
      const errors = [];
      if (!this.$v.editedItem.kpp.$dirty) return errors;
      !this.$v.editedItem.kpp.maxLength &&
        errors.push(VALID_ERRORS.maxLength(9));
      !this.$v.editedItem.kpp.numeric && errors.push(VALID_ERRORS.numeric);
      return errors;
    },

    ogrnErrors() {
      const errors = [];
      if (!this.$v.editedItem.ogrn.$dirty) return errors;
      !this.$v.editedItem.ogrn.required && errors.push(VALID_ERRORS.required);
      !this.$v.editedItem.ogrn.maxLength &&
        errors.push(VALID_ERRORS.maxLength(15));
      !this.$v.editedItem.ogrn.numeric && errors.push(VALID_ERRORS.numeric);
      return errors;
    },

    creationDateErrors() {
      const errors = [];
      if (!this.$v.editedItem.creationDate.$dirty) return errors;
      !this.$v.editedItem.creationDate.maxLength &&
        errors.push(VALID_ERRORS.maxLength(10));
      !this.$v.editedItem.creationDate.confirmData &&
        errors.push(VALID_ERRORS.dateMatch);
      return errors;
    },

    bankErrors() {
      const errors = [];
      if (!this.$v.editedItem.bank.$dirty) return errors;
      !this.$v.editedItem.bank.maxLength &&
        errors.push(VALID_ERRORS.maxLength(199));
      return errors;
    },

    snilsErrors() {
      const errors = [];
      if (!this.$v.editedItem.snils.$dirty) return errors;
      !this.$v.editedItem.snils.required && errors.push(VALID_ERRORS.required);
      !this.$v.editedItem.snils.maxLength &&
        errors.push(VALID_ERRORS.maxLength(11));
      !this.$v.editedItem.snils.numeric && errors.push(VALID_ERRORS.numeric);
      return errors;
    },

    passportNumErrors() {
      const errors = [];
      if (!this.$v.editedItem.passportNum.$dirty) return errors;
      !this.$v.editedItem.passportNum.required &&
        errors.push(VALID_ERRORS.required);
      !this.$v.editedItem.passportNum.maxLength &&
        errors.push(VALID_ERRORS.maxLength(10));
      !this.$v.editedItem.passportNum.numeric &&
        errors.push(VALID_ERRORS.numeric);
      return errors;
    },

    passportOrgErrors() {
      const errors = [];
      if (!this.$v.editedItem.passportOrg.$dirty) return errors;
      !this.$v.editedItem.passportOrg.required &&
        errors.push(VALID_ERRORS.required);
      !this.$v.editedItem.passportOrg.maxLength &&
        errors.push(VALID_ERRORS.maxLength(199));
      return errors;
    },

    telephoneErrors() {
      const errors = [];
      if (!this.$v.editedItem.telephone.$dirty) return errors;
      !this.$v.editedItem.telephone.required &&
        errors.push(VALID_ERRORS.required);
      !this.$v.editedItem.telephone.maxLength &&
        errors.push(VALID_ERRORS.maxLength(11));
      return errors;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.editedItem.email.$dirty) return errors;
      !this.$v.editedItem.email.email && errors.push(VALID_ERRORS.email);
      !this.$v.editedItem.email.maxLength &&
        errors.push(VALID_ERRORS.maxLength(99));
      return errors;
    },

    addressErrors() {
      const errors = [];
      if (!this.$v.editedItem.address.$dirty) return errors;
      !this.$v.editedItem.address.required &&
        errors.push(VALID_ERRORS.required);
      !this.$v.editedItem.address.maxLength &&
        errors.push(VALID_ERRORS.maxLength(199));
      return errors;
    }
  },
  methods: {
    async itemConvertion() {
      this.editedItem.createdBy = this.$store.state.userId;
      return true;
    },
    isValid() {
      this.$v.$touch();
      // Это валидация формы.
      if (
        (+this.editedItem.orgType === 1 &&
          checkValid(
            this.$v.editedItem,
            'name',
            'snils',
            'passportNum',
            'passportOrg',
            'inn',
            'address',
            'telephone',
            'email'
          )) ||
        (+this.editedItem.orgType === 0 &&
          checkValid(
            this.$v.editedItem,
            'name',
            'shortName',
            'director',
            'kpp',
            'inn',
            'ogrn',
            'creationDate',
            'bank',
            'address',
            'telephone',
            'email'
          ))
      ) {
        return true;
      }
      return false;
    }
  }
};

//   async mounted() {
//     // Событие открытия окна извне
//     this.$bus.$on("partnerOpenItem", async ({ editMode, item }) => {
//       this.dialog = true;
//       this.editMode = editMode;

//       // Если в режиме редактирования, то пытаемся также загрузить редактируемый объект
//       if (editMode) {
//         const loadedPartner = await this.partnerFetch(item.id);

//         this.editedItem = {
//           ...loadedPartner.data.fetchPartner,
//           orgType: +loadedPartner.data.fetchPartner.orgType,
//         };
//       }
//     });
//   },
</script>

<style></style>

<style></style>
