<template>
  <v-row>
    <v-col>
      <item-representation :title="title" :view="view" :selected="selected" />
    </v-col>
    <v-col md="auto">
      <univarsal-list
        :title="title"
        :items="items"
        :headers="headers"
        :singleSelect="singleSelect"
        :toSelect="selected"
        :loadedSelection="loadedSelection"
        @itemsSelected="itemsSelected($event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import UnivarsalList from "../UniList";
import ItemRepresentation from "./ItemRepresentation";

export default {
  components: {
    "univarsal-list": UnivarsalList,
    "item-representation": ItemRepresentation,
  },
  props: {
    tag: String,
    title: String,
    singleSelect: {
      type: Boolean,
      default: true,
    },
    view: {
      type: String,
      default: "string", // string, image
    },
    toSelect: Object || Array,
  },
  data: () => ({
    items: [],
    headers: [],
    selected: [],
    loadedSelection: [],
  }),
  watch: {
    items(value) {
      if (this.toSelect) {
        this.selected = value.filter(
          // TODO: проверяет только на выбор одного элемента this.toSelect._id, нужно позволить передавать в toSelect массив и проверять всё!
          (v) => v._id.toString() === this.toSelect._id.toString()
        );
      }
    },
    selected(value) {
      // if (!value) {
      //   return;
      // }
      // // Перечень выбранных объектов
      // // Компонент возвращает выбранные элементы
      // this.onSelect(
      //   value.map(i => {
      //     return i._id;
      //   })
      // );
    },
    // Если есть что редактировать то надо внеси соответсвующие коррективы
    // toSelect(value) {
    //   this.selected = value;
    // }
  },
  async mounted() {
    this.items = await this.$store.dispatch("uniFetchAll", {
      name: this.tag,
    });
    if (this.toSelect && this.toSelect.length > 0) {
      this.loadedSelection = this.toSelect;
    }
    this.headers = this.$store.getters.allHeaders(this.tag);
  },
  methods: {
    onSelect(itemsSelected) {
      this.$emit("selected", [...itemsSelected]);
    },
    itemsSelected(items) {
      this.selected = items;
      if (!items) {
        return;
      }
      // Перечень выбранных объектов
      // Компонент возвращает выбранные элементы
      // ВОЗВРАЩАЕТСЯ ЗНАЧЕНИЕ ТУТ --------------> !!!
      // console.log('ItemSelector returns:', items);
      this.onSelect(
        items.map((i) => {
          return i._id;
        })
      );
    },
  },
};
</script>

<style></style>
